import React, { FunctionComponent, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Context } from '../context/Context';
import { Brand, CORPORATE_ACCOUNTS, ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY, WHITELABEL_BRANDS } from './constants';
import { canDisplayCommentsAndPicturesScreen, surveyHasExpired } from './Utils';
import { AppContext, TRACKS } from '../interfaces';

// These guards are to inhibit user from going back and changing information.
export const DispatchGuard: FunctionComponent<{ component: JSX.Element }> = ({ component }): JSX.Element => {
	const { brand, service, actionType, currentTrack, situation } = useContext(Context) as AppContext;
	const { serviceNumber, driver, schedule } = service;
	const location = useLocation();

	const tracking = <Redirect to={driver?.id ? '/tracking' : '/tracking/loading'} />;
	const serviceSummary = <Redirect to="/service-summary" />;

	const redirect: React.ReactElement = schedule || currentTrack === TRACKS.HOME ? serviceSummary : tracking;

	const displayCommentsAndPicturesScreen = canDisplayCommentsAndPicturesScreen(
		service.serviceNumber,
		brand as Brand,
		currentTrack ?? service.serviceType,
		situation
	);

	if (!serviceNumber || actionType === 'confirmData' || displayCommentsAndPicturesScreen) {
		return component;
	} else {
		return redirect;
	}
};

export const SurveyGuard: FunctionComponent<{ component: JSX.Element }> = ({ component }): JSX.Element => {
	const { brand, service } = useContext(Context) as AppContext;

	let redirectUrl = '';

	if (service.surveys?.answered) {
		// survey answered -> Go to other screen
		if (WHITELABEL_BRANDS.includes(brand)) {
			redirectUrl = '/feedback';
		} else {
			redirectUrl = '/social';
		}
	} else {
		// Survey have not answered and the time to that expired.
		if (service?.serviceType && surveyHasExpired(service.endDate, service.serviceType)) {
			redirectUrl = '/social';
		}

		// Survey not answered - go to survey screen
		if (ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY.includes(service.sfIdAccount as string)) {
			// unless the account has surveys disabled
			redirectUrl = '/finished';
		}
	}

	return redirectUrl ? <Redirect to={redirectUrl} /> : component;
};

export const CorporateGuard: FunctionComponent<{ component: JSX.Element }> = ({ component }): JSX.Element => {
	const { brand } = useContext(Context) as AppContext;

	return CORPORATE_ACCOUNTS.includes(brand) ? (
		component
	) : (
		<Redirect to={WHITELABEL_BRANDS.includes(brand) ? '/corporate/home' : '/'} />
	);
};
