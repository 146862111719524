import { Account, CustomerContract } from '../interfaces';
import { PENDO_APIKEY } from '../utils/constants';

export const loadPendoScript = (customerContract: CustomerContract, account: Account): Promise<void> => {
	return new Promise((resolve, reject) => {
		const cleanup = () => {
			if (script && script.parentNode) {
				script.parentNode.removeChild(script);
			}
		};

		if (window.pendo?.isReady()) {
			initializePendoWithCustomerContract(customerContract, account);
			resolve();
			return;
		}

		const script = document.createElement('script');
		script.src = `https://cdn.pendo.io/agent/static/${PENDO_APIKEY}/pendo.js`;
		script.async = true;

		script.onload = () => {
			initializePendoWithCustomerContract(customerContract, account);
			console.log('Pendo script loaded and initialized.');
			resolve();
		};

		script.onerror = () => {
			cleanup();
			console.error('Failed to load the Pendo script.');
			reject(new Error('Failed to load the Pendo script'));
		};

		window.addEventListener('unload', cleanup);
		document.body.appendChild(script);
	});
};

const initializePendoWithCustomerContract = (customerContract: CustomerContract, account: Account): void => {
	try {
		if (!customerContract?.contract_id || !account?._id) {
			console.error('Missing required data for Pendo initialization');
			return;
		}

		const visitor = {
			id: String(customerContract.contract_id),
			phone: customerContract.customer?.phone_number1 || '',
			branch: account.branch || '',
			fullName: customerContract.customer?.full_name || '',
		};

		const pendoAccount = {
			id: String(account._id),
			branch: account.branch || '',
			name: account.name || '',
			salesforce: account.salesforce || '',
		};

		window.pendo.initialize({
			apiKey: PENDO_APIKEY,
			visitor,
			account: pendoAccount,
		});

		console.log('Pendo initialized successfully.');
	} catch (error) {
		console.error('Error initializing Pendo:', error instanceof Error ? error.message : error);
	}
};
